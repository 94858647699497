import React, { useState, useEffect, useReducer } from 'react';
import { graphql, Link } from 'gatsby';
import { Table } from 'react-bootstrap';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../../modules/common/components/seo';
import { SectionHeader } from '../../../modules/common/components/section-header';

import lodash from 'lodash';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Options } from '@contentful/rich-text-react-renderer';
import { INikkeCharacterNode } from '../../../modules/common/model/graphql-types';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { NikkeSpecialitiesArray } from '../../../modules/nikke/character/model/nikke-specialities-array';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import '../../generic-page.scss';
import './harmony-cubes.scss';
import { NikkeCubeTable } from '../../../modules/nikke/common/components/nikke-cube-table';

interface INikkeCharacterNodes {
  nodes: INikkeCharacterNode[];
}

interface INikkeCharacterEntry {
  allCharacters: INikkeCharacterNodes;
}

interface IProps {
  data: INikkeCharacterEntry;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

const NikkeHarmonyTips: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'R', value: 'r' },
          { label: 'SR', value: 'sr' },
          { label: 'SSR', value: 'ssr' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_electric.png"
                width={20}
                alt="Electric"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_fire.png"
                width={20}
                alt="Fire"
              />
            )
          },
          {
            value: 'Iron',
            tooltip: 'Iron',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_iron.png"
                width={20}
                alt="Iron"
              />
            )
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_water.png"
                width={20}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_wind.png"
                width={20}
                alt="Wind"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Assault Rifle',
            tooltip: 'Assault Rifle',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_ar.png"
                width={40}
                alt="Assault"
              />
            )
          },
          {
            value: 'Minigun',
            tooltip: 'Minigun',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_minigun.png"
                width={40}
                alt="Minigun"
              />
            )
          },
          {
            value: 'Rocket Launcher',
            tooltip: 'Rocket Launcher',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_rocket.png"
                width={40}
                alt="Rocket Launcher"
              />
            )
          },
          {
            value: 'Shotgun',
            tooltip: 'Shotgun',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_shotgun.png"
                width={40}
                alt="Shotgun"
              />
            )
          },
          {
            value: 'SMG',
            tooltip: 'SMG',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_smg.png"
                width={40}
                alt="SMG"
              />
            )
          },
          {
            value: 'Sniper Rifle',
            tooltip: 'Sniper Rifle',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_sniper.png"
                width={40}
                alt="Sniper Rifle"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_attacker.png"
                width={20}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_support.png"
                width={20}
                alt="Supporter"
              />
            )
          }
        ]
      },
      {
        key: 'burst',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: '1',
            tooltip: 'Burst I',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_1.png"
                width={20}
                alt="Type I"
              />
            )
          },
          {
            value: '2',
            tooltip: 'Burst II',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_2.png"
                width={20}
                alt="Type II"
              />
            )
          },
          {
            value: '3',
            tooltip: 'Burst III',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_3.png"
                width={20}
                alt="Type III"
              />
            )
          }
        ]
      },
      {
        key: 'manufacturer',
        type: 'dropdown',
        values: [
          { label: 'Any Manufacturer', value: 'all' },
          {
            label: 'Abnormal',
            value: 'abnormal'
          },
          {
            label: 'Elysion',
            value: 'elysion'
          },
          {
            label: 'Missilis',
            value: 'missilis'
          },
          {
            label: 'Pilgrim',
            value: 'pilgrim'
          },
          {
            label: 'Tetra',
            value: 'tetra'
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: NikkeSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.element.indexOf(activeFilters.element) > -1
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.manufacturer && activeFilters.manufacturer !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.manufacturer.toLowerCase() ===
            activeFilters.manufacturer.toLowerCase()
        );
      }
      if (activeFilters.burst && activeFilters.burst !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.burstType.toLowerCase() === activeFilters.burst.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.specialities &&
            emp.specialities.length > 0 &&
            emp.specialities.indexOf(activeFilters.speciality) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page characters-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Harmony Cubes - suggestions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_cubestips.png"
            alt="Harmony Cubes - suggestions"
          />
        </div>
        <div className="page-details">
          <h1>Harmony Cubes - suggestions</h1>
          <h2>
            This guide will help you pick the right Harmony Cube for your
            NIKKEs.
          </h2>
          <p>
            Last updated: <strong>20/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <SectionHeader title="Harmony Cubes" />
      <div className="employees-filter-bar nikke">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <Table striped bordered responsive className="harmony-table">
        <thead>
          <tr>
            <th>Character</th>
            <th>Pre-overload</th>
            <th>Post-overload</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {visibleCharacters.map((emp, index) => {
            return (
              <tr key={index}>
                <td className="with-char">
                  <NikkeCharacter
                    slug={emp.slug}
                    mode="icon"
                    showLabel
                    onlyName
                    enablePopover
                  />
                </td>
                {emp.harmonyCubes.pre_1 != '' && (
                  <>
                    <td>
                      <ul>
                        <li>
                          <NikkeCubeTable cube={emp.harmonyCubes.pre_1} />
                        </li>
                        {emp.harmonyCubes.pre_2 && (
                          <li>
                            <NikkeCubeTable cube={emp.harmonyCubes.pre_2} />
                          </li>
                        )}
                        {emp.harmonyCubes.pre_3 && (
                          <li>
                            <NikkeCubeTable cube={emp.harmonyCubes.pre_3} />
                          </li>
                        )}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>
                          <NikkeCubeTable cube={emp.harmonyCubes.post_1} />
                        </li>
                        {emp.harmonyCubes.post_2 && (
                          <li>
                            <NikkeCubeTable cube={emp.harmonyCubes.post_2} />
                          </li>
                        )}
                        {emp.harmonyCubes.post_3 && (
                          <li>
                            <NikkeCubeTable cube={emp.harmonyCubes.post_3} />
                          </li>
                        )}
                      </ul>
                    </td>
                    <td className="comment">
                      {emp.harmonyCubesInfo && (
                        <>{renderRichText(emp.harmonyCubesInfo, options)}</>
                      )}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../../images/counterside/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default NikkeHarmonyTips;

export const Head: React.FC = () => (
  <Seo
    title="Harmony Cubes - suggestions | NIKKE | Prydwen Institute"
    description="This guide will help you pick the right Harmony Cube for your NIKKEs."
    game="nikke"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulNikkeCharacter(
      filter: { isReviewPending: { ne: true } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        updatedAt
        createdAt
        unitId
        name
        slug
        rarity
        element
        weapon
        class
        burstType
        manufacturer
        squad
        specialities
        isNew
        isReviewPending
        harmonyCubes {
          pre_1
          pre_2
          pre_3
          post_1
          post_2
          post_3
        }
        harmonyCubesInfo {
          raw
        }
      }
    }
  }
`;
